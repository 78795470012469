<template>
  <div class="container-fluid shadow p-3 pe-5 ps-5 mb-5">
    <div class="row flex-nowrap">
      <div class="col navbar-light p-0 m-0">
        <span class="h3">Profiles</span>
        <div class="float-end">
          <display-picture :is-left="true" class="rounded-circle"></display-picture>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <button class="btn btn-primary mb-5" @click="addUser" role="button">Add User</button>
      <DataTable2
          :headers="headers"
          :actions="actions"
          :api="getProfiles"
          :fields="['username']"
          :trigger="trigger"
      />
    </div>
  </div>
</template>

<script>
import DataTable2 from 'data-table/src/components/DataTable2'
import {services} from "../service/user-api";
import InsertModalService from 'modal/src/services/InsertModal'
import ModalService from 'modal/src/services/Modal'
import DisplayPicture from "node-back-client/src/components/DisplayPicture";
export default {
  name: "Accounts",
  components:{
    DataTable2,DisplayPicture
  },
  methods : {
    addUser() {
      let entity = {};
      InsertModalService.Show({
        title: "Add User",
        type: {
          username: "text",
          name: "text",
        },
        model: entity
      },async () => {
        await services.user.Create(entity);
        this.trigger.refresh();
      });
    },
    getProfiles() {
      console.log(...arguments);
      return services.user.GetUsers(...arguments);
    }
  },
  data() {
    return {
      trigger:{},
      headers : {
        name:"Name",
        username:"Username"
      },
      actions: [
        // {
        //   classNames:"fa fa-edit",
        //   callback: (entity) => {
        //     InsertModalService.Show({
        //       title:"Edit Profile",
        //       type: {
        //         email:"email",
        //         username:"text",
        //         newPassword:"password",
        //         name: "text",
        //       },
        //       model: entity
        //     }, () => {
        //       services.user.Save(entity);
        //     })
        //   }
        // },
        {
          classNames:"fa fa-trash text-danger",
          callback: (entity) => {
            ModalService.Show({
              title: "Do you want to remove",
              message : `Remove ${entity.username}`,
            }, async () => {
              await services.user.Delete(entity);
              this.trigger.refresh();
            })
          }
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>